import { Grid } from '@mui/material';
import { FC } from 'react';
import * as styles from './Welcome.styles';

export const Welcome: FC = () => {
    return (
        <>
            <div className={styles.iconHeader}>nl</div>
            <div className={styles.scrollIndicatorContainer}>
                <a href="#about">v</a>
            </div>
            <div className={styles.welcomeContainer}>
                <div className={styles.heroContainer}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            Hello, world! I'm Nicole
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            Welcome to my portfolio!
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
};

export default Welcome;
