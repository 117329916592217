import { Grid, Button, Modal, Box, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { projectsData } from './Projects.data';
import * as styles from './Projects.styles';

export const Projects: FC = () => {
    return (
        <>
            <h1>Stuff I built</h1>
            <div className={styles.learnMoreText}>(tap to learn more!)</div>
            <Grid container spacing={3}>
                {projectsData.map((data, i) => {
                    return (
                        <Grid item xs={12} sm={12} md={4} lg={4} key={i}>
                            <ProjectCard
                                imagePath={data.image}
                                title={data.title}
                                description={data.description}
                                skills={data.skills}
                                repoUrl={data.repoUrl}
                            ></ProjectCard>
                            <div className={styles.projectCardCaption}>
                                {data.title}
                            </div>
                        </Grid>
                    );
                })}
            </Grid>
            <div></div>
            <span className={styles.gitHubLinkContainer}>
                ... more on my 👾{' '}
                <a href="https://github.com/nloh15" target="_blank">
                    GitHub
                </a>
            </span>
        </>
    );
};

interface ProjectCardProps {
    imagePath: string;
    title: string;
    description: string;
    skills: string[];
    repoUrl: string;
}

const ProjectCard: FC<ProjectCardProps> = ({
    imagePath,
    title,
    description,
    skills,
    repoUrl,
}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <>
            <div className={styles.projectCardContainer} onClick={handleOpen}>
                <img
                    className={styles.projectCardImage}
                    src={require(`${imagePath}`)}
                />
            </div>
            <Modal open={open} onClose={handleClose}>
                <div className={styles.projectModalContainer}>
                    <div className={styles.projectModalItem}>
                        <h3>{title}</h3>
                    </div>
                    <div className={styles.projectModalItem}>{description}</div>
                    <div className={styles.projectModalItem}>
                        <b>Skills: </b>
                        {skills.map((skill: string, i: number) => (
                            <span key={i}>
                                {i > 0 && ', '} {skill}
                            </span>
                        ))}
                    </div>
                    {repoUrl != '' && (
                        <span>
                            🔗 <a href={repoUrl}>View Repo</a>
                        </span>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default Projects;
