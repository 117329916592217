import { css } from '@emotion/css';

export const heroText = css({
    fontFamily: 'Mark Pro, sans-serif',
    fontSize: '7rem',
    letterSpacing: '-.03em',
});

export const heroContainer = css({});

export const experienceCardContainer = css({
    padding: '1.5em 2em',
    borderRadius: '1.5em',
    boxShadow: '1px 1px 3px #d6d6d6',
    verticalAlign: 'center',
});

export const experienceCardLeft = css({
    width: '20%',
    '@media (max-width: 600px)': {
        width: '100%',
        textAlign: 'center',
        marginBottom: '8px',
    },
});

export const experienceCardRight = css({
    width: '80%',
    '@media (max-width: 600px)': {
        width: '100%',
        textAlign: 'center',
    },
});

export const experienceLogo = css({
    maxWidth: '80px',
    maxHeight: '80px',
    marginRight: '20px',
});

export const experienceTitle = css({
    fontWeight: 'bold',
});

export const experienceCardDescription = css({
    marginTop: '-10px',
});
