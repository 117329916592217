import { FC } from 'react';
import * as styles from './Contact.styles';

export const Contact: FC = () => {
    return (
        <>
            <div className={styles.contactContainer}>
                <h2>Let's get in touch!</h2>
                <br></br>
                <span className={styles.contactItem}>
                    💼{' '}
                    <a href="https://www.linkedin.com/in/nloh/" target="_blank">
                        LinkedIn
                    </a>
                </span>
                <span className={styles.contactItem}>
                    📨 <a href="mailto:nicole.aloh@gmail.com">Email</a>
                </span>
                <br></br>
            </div>
        </>
    );
};

export default Contact;
