import { Grid } from '@mui/material';
import { FC } from 'react';
import * as styles from './Experience.styles';
import { experienceData } from './Experience.data';

export const Experience: FC = () => {
    return (
        <>
            <h1>Where I've worked</h1>
            <Grid container spacing={3}>
                {experienceData.map((data, i) => {
                    return (
                        <Grid item xs={12} sm={12} md={4} lg={4} key={i}>
                            <ExperienceCard
                                company={data.name}
                                title={data.role}
                                emoji={data.emoji}
                                link={data.companyUrl}
                                date={data.dates}
                            ></ExperienceCard>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

interface ExperienceCardProps {
    company: string;
    title: string;
    emoji: string;
    link: string;
    date: string;
}

// TO DO: Make responsive
const ExperienceCard: FC<ExperienceCardProps> = ({
    company,
    title,
    emoji,
    link,
    date,
}) => {
    return (
        <>
            <div className={styles.experienceCardContainer}>
                <div className={styles.experienceTitle}>
                    {emoji}{' '}
                    <a href={link} target="_blank">
                        {company}
                    </a>
                </div>
                <br></br>
                <div className={styles.experienceCardDescription}>
                    <span>{title}</span>
                    <br></br>
                    <span>{date}</span>
                </div>
            </div>
        </>
    );
};
export default Experience;
