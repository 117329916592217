import * as styles from './App.styles';
import { Welcome } from './components/Welcome';
import { Experience } from './components/Experience';
import { Projects } from './components/Projects';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import { About } from './components/About';

function App() {
    return (
        <>
            <div className={styles.App}>
                <Welcome></Welcome>
                <About></About>
                <Experience></Experience>
                <Projects></Projects>
                <Contact></Contact>
                <Footer></Footer>
            </div>
        </>
    );
}

export default App;
