import { css, keyframes } from '@emotion/css';

export const heroText = css({
    fontFamily: 'Mark Pro, sans-serif',
    letterSpacing: '-.03em',
});

export const welcomeContainer = css({
    height: '100vh',
    width: '100%',
    backgroundSize: 'cover',
    top: '0',
});

export const heroContainer = css({
    overflow: 'hidden',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
});

export const iconHeader = css({});

export const scrollIndicatorContainer = css({
    overflow: 'hidden',
    position: 'absolute',
    bottom: '10%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
});
