import { FC } from 'react';
import * as styles from './About.styles';

export const About: FC = () => {
    return (
        <>
            <div id="about">
                <h1>About Me</h1>
                <div className={styles.heroText}>
                    👋🏻 Hi there, I’m Nicole! I graduated from UC Irvine in 2021
                    with a Bachelor's in Computer Science, specializing in
                    systems and software. I'm currently a software engineer at
                    TikTok, working on the shopping guidance flow on the
                    e-commerce platform. Previously, I was at Snap working on
                    internal tools to help boost team productivity. I am
                    passionate about utilizing the intersection between design,
                    systems and code to find solutions to day-to-day problems.
                    Aside from designing and programming, I bake 🧁, do yoga 🧘🏻‍♀️
                    and take photos 📸!
                </div>
            </div>
        </>
    );
};

export default About;
