export const projectsData = [
    {
        image: './assets/kuy.png',
        title: 'Kuy!',
        description:
            'Mobile application for group hangout matching at restaurants based on location. Developed using React Native with UI mockup designed with Figma. Incorporated API calls to fetch and update data to and from the database for various in-app scenarios. Team project for Neighborhood Hacks 2021.',
        skills: ['React Native', 'JavaScript', 'Figma', 'UI/UX'],
        repoUrl: 'https://github.com/nloh15/kuy/tree/main/pages',
    },
    {
        image: './assets/sleep-tracker.png',
        title: 'Sleep Tracker',
        description:
            'Mobile application for overnight sleep and daily sleepiness tracking, built using Ionic framework. Sleep data is analyzed and simple insight is displayed on the history page. Utilized native Cordova plugin to push local notifications to users for tracking reminders.',
        skills: ['Ionic', 'TypeScript', 'UI/UX'],
        repoUrl: 'https://github.com/nloh15/sleep-tracker',
    },
    {
        image: './assets/spotify-browser.png',
        title: 'Spotify Browser',
        description:
            'Simple Spotify browser implementation in Angular with ability to search artists, albums and tracks. Utilized the Spotify Web API to call and fetch data to display.',
        skills: ['Angular', 'TypeScript', 'Express'],
        repoUrl: 'https://github.com/nloh15/spotify-browser',
    },
    {
        image: './assets/vena-vitals.png',
        title: 'Vena Vitals',
        description:
            'Team project for an early-stage startup for our capstone project course. iOS mobile application for data visualization based on data collected from a blood pressure measurement device. Developed using Swift. Functionalities include customizable waveform for blood pressure data, and peak detection.',
        skills: ['Swift', 'UI/UX'],
        repoUrl: '',
    },
    {
        image: './assets/mentr.png',
        title: 'mentr.',
        description:
            'Mentor-mentee matching web application with a Tinder-inspired swiping process. Utilized Firebase to store data in real-time and to support the login, rating and chat system. Team project during UCI Hackathon 2020, awarded Best use of Sketch.',
        skills: ['Angular', 'TypeScript', 'UI/UX'],
        repoUrl: 'hhttps://github.com/nloh15/mentr',
    },
];
