import { css } from '@emotion/css';

export const App = css({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '2em',
});

export const component = css({
    margin: '2em 0em',
});
