import { FC } from 'react';
import * as styles from './Footer.styles';

export const Footer: FC = () => {
    return (
        <>
            <div className={styles.footerContainer}>Made with ❤️ by Nicole</div>
        </>
    );
};

export default Footer;
