import { css } from '@emotion/css';

const projectCardImageOnHover = css({
    cursor: 'pointer',
    outline: '3px solid #aac2d1',
});

export const projectCardImage = css({
    opacity: '1',
    backfaceVisibility: 'hidden',
    width: '100%',
    borderRadius: '1.5em',
    boxShadow: '1px 1px 3px #d6d6d6',
    '&:hover': projectCardImageOnHover,
});

export const projectCardContainer = css({
    borderRadius: '1.5em',
    display: 'flex',
    flexWrap: 'wrap',
    verticalAlign: 'center',
});

export const projectCardCaption = css({
    marginTop: '5px',
});

export const projectModalContainer = css({
    borderRadius: '1.5em',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '2em',
    minWidth: '500px',
    '@media (max-width: 600px)': {
        padding: '1.5em',
        minWidth: '300px',
    },
});

export const projectModalItem = css({
    margin: '8px',
});

export const gitHubLinkContainer = css({
    marginTop: '2em',
    textAlign: 'center',
});

export const learnMoreText = css({
    marginTop: '-1.25em',
    marginBottom: '1em',
});
