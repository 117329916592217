export const experienceData = [
    {
        name: 'TikTok',
        emoji: '🎶',
        companyUrl: 'https://shop.tiktok.com/business/us',
        role: 'Software Engineer, Global E-Commerce',
        dates: 'Jan 2023 - present',
    },
    {
        name: 'Snap Inc.',
        emoji: '👻',
        companyUrl: 'https://snap.com/',
        role: 'Software Engineer, Enterprise Engineering',
        dates: 'Aug 2021 - Nov 2022',
    },
    {
        name: 'VComply',
        emoji: '🗂️',
        companyUrl: 'https://www.v-comply.com/',
        role: 'Software Engineer Intern',
        dates: 'Jun 2021 - Aug 2021',
    },
    {
        name: 'UCI Office of Information Technology',
        emoji: '🖥️',
        companyUrl: 'https://www.oit.uci.edu/',
        role: 'Web Developer / Graphic Designer',
        dates: 'Nov 2019 - Mar 2021',
    },
    {
        name: 'UCI School of Information & Computer Sciences',
        emoji: '📝',
        companyUrl: 'https://www.ics.uci.edu/',
        role: 'Learning Assistant',
        dates: 'Oct 2020 - Mar 2021',
    },
    {
        name: 'Diablo Valley College Math Lab',
        emoji: '📐',
        companyUrl:
            'https://www.dvc.edu/current/student-centers/math-engineering.html',
        role: 'Math Tutor',
        dates: 'Jun 2018 - May 2019',
    },
    {
        name: 'Ruangguru',
        emoji: '📚',
        companyUrl: 'https://www.ruangguru.com/',
        role: 'Content Marketing Intern',
        dates: 'Jul 2018 - Aug 2018',
    },
];
